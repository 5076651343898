import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import headerImg from "src/assets/img/home/header.png";
import headerImgEn from "src/assets/img/home/home-header-en.png";
import headerImgAr from "src/assets/img/home/home-header-ar.png";
import followerImg from "src/assets/svg-icons/home/follower.svg";

const Header = () => {
  const { t, i18n } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <Stack
      p={{ xs: "60px 16px 60px 16px", lg: "80px 0 16px 0" }}
      spacing={{ xs: 3, md: "32px" }}
      alignItems={"center"}
    >
      <Typography
        maxWidth={{ xs: "100%", md: "80%", lg: "981px" }}
        fontSize={{ xs: "20px", md: "32px" }}
        fontWeight={{ xs: 800, md: 800 }}
        lineHeight={{ xs: "130%", md: "150%" }}
        color={"#1E1E1E"}
        align="center"
      >
        {t("headerTitle")}
      </Typography>
      {/* <Button
        variant="contained"
        onClick={() => navigate("/sign-up")}
        sx={{
          height: "56px",
          borderRadius: "12px",
        }}
      >
        {t("createAccountFree")}
      </Button> */}
      <Box data-aos="fade-up" data-aos-offest="200" data-aos-duration="1000">
        <img
          width={"100%"}
          src={i18n.language === "en" ? headerImgEn : headerImgAr}
          alt=""
        />
      </Box>

      <Box
        data-aos="fade-up"
        data-aos-offest="200"
        data-aos-duration="1000"
        position={"relative"}
        sx={{ mb: { xs: "", lg: "-80px !important" } }}
        display={"flex"}
        width="100%" // Ensure full width of the parent container
        paddingX={{ lg: "40px" }}
      >
        <Box
          width="100%"
          position="relative"
          paddingTop="56.25%"
          overflow="hidden"
          borderRadius="16px"
          zIndex={2}
        >
          <iframe
            src="https://www.youtube.com/embed/Y6OA8axWstk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute", // Position absolutely to fill the Box
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "16px", // Optional: add border radius
            }}
          ></iframe>
        </Box>

        <Box
          position={"absolute"}
          bottom={-20}
          left={-12}
          display={{ xs: "none", md: "block" }}
          zIndex={1}
        >
          <img src={followerImg} alt="" />
        </Box>
      </Box>
    </Stack>
  );
};

export default Header;
